import React, { useState, useEffect } from "react"

const FinalButton = ({ inViewport, forwardedRef, enterCount, cardTextFirstView }) => {
  const [css, setCss] = useState("button buttonSecond")

  useEffect(() => {
    if (inViewport && enterCount === 1) {
      setCss("button buttonSecond buttonPopSecond")
    }

    if (enterCount > 1) {
      setCss("button buttonSecond show")
    }
  }, [enterCount, inViewport])

  return (
    <div className={css} ref={forwardedRef}>
      Nakoupit?
    </div>
  )
}

export default FinalButton
