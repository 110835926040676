import { useStaticQuery, graphql } from "gatsby"

const useFooterImages = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allFile(filter: { relativeDirectory: { eq: "logo" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 117) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `)

  const logoSources = [
    data.allFile.nodes[2].childImageSharp.fluid,
    {
      ...data.allFile.nodes[1].childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
    {
      ...data.allFile.nodes[0].childImageSharp.fluid,
      media: `(max-width: 640px)`,
    },
  ]
  return logoSources
}

export default useFooterImages
