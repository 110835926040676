import React, { useState, useRef, useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = () => {
  const [headerSticky, setHeaderSticky] = useState(false)
  const [menu, setMenu] = useState(false)
  const [myMarginTop, setMyMarginTop] = useState(null)

  const header = useRef()

  useEffect(() => {
    /* const header = document.getElementById("header");
    const sticky = header.offsetTop; */
    const getSticky = () => {
      if (window.pageYOffset > header.current.offsetTop) {
        setHeaderSticky(true)
      } else {
        setHeaderSticky(false)
      }
    }

    window.addEventListener("scroll", getSticky)

    return () => {
      window.removeEventListener("scroll", getSticky)
    }
  }, [])

  useEffect(() => {
    const scrollMargin = () => {
      window.pageYOffset < 45 ? setMyMarginTop(45 - ((45 / 100) * window.pageYOffset) / 0.5 + "px") : setMyMarginTop("0px")
    }

    window.addEventListener("scroll", scrollMargin, true)

    return () => {
      window.removeEventListener("scroll", scrollMargin, true)
    }
  }, [])

  const toggleMenu = () => {
    setMenu(!menu)
  }
  const toggleMenuKey = key => {
    if (key.keyCode === 13) {
      setMenu(!menu)
    }
  }

  return (
    <header id="header" ref={header} className={headerSticky ? "sticky" : ""} style={{ marginTop: myMarginTop !== null ? myMarginTop : "45px" }}>
      <div className="inner">
        <div className="left">
          <div className={`menu ${menu ? "open" : "close"}`} onClick={toggleMenu} onKeyPress={toggleMenuKey} role="button" tabIndex="0" aria-label="menuButton">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
          </div>
          <nav className={`${menu ? "open" : ""}`}>
            <AnchorLink to="/#forestSection">Scéna 1</AnchorLink>
            <AnchorLink to="/#textSection">Scéna 2</AnchorLink>
            <AnchorLink to="/#bacteriaSection">Scéna 3</AnchorLink>
          </nav>
        </div>
        <div className="right">
          <a href="#a">
            <img src="img/facebook.svg" width="100%" height="auto" alt="facebookIcon" />
          </a>
          <a href="#a">
            <img src="img/instagram.svg" width="100%" height="auto" alt="instagramIcon" />
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
