import React from "react"
import Img from "gatsby-image"

const ResponsiveBacteriaTopRight = ({ inViewport, forwardedRef, virusImg, trususImg, salamolosusImg }) => {
  const cssVirus = inViewport ? "virus float3" : "virus"
  const cssTrusus = inViewport ? "trusus float2" : "trusus"
  const cssSalamolosus = inViewport ? "salamolosus float" : "salamolosus"

  return (
    <div className="top" ref={forwardedRef}>
      <div className={cssVirus}>
        <Img fluid={virusImg} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="virus" />
      </div>
      <div className={cssTrusus}>
        <Img fluid={trususImg} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="trusus" />
      </div>
      <div className={cssSalamolosus}>
        <Img fluid={salamolosusImg} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="salamolosus" />
      </div>
    </div>
  )
}

export default ResponsiveBacteriaTopRight
