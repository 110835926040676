import React, { useState, useEffect } from "react"
import useWidth from "../../hooks/useWidth"
import BacteriaSectionDesktop from "./BacteriaSectionDesktop"
import BacteriaSectionResponsive from "./BacteriaSectionResponsive"

const BacteriaSection = () => {
  const [wWidth, setwWidth] = useState(0)
  const viewportWidth = useWidth()

  useEffect(() => {
    setwWidth(viewportWidth.width)
  }, [viewportWidth])

  return (
    <>
      <div className="bacteriaOverlay" id="bacteriaSection" />
      <section className="bacteriaSection">{wWidth > 1440 ? <BacteriaSectionDesktop /> : <BacteriaSectionResponsive />}</section>
    </>
  )
}

export default BacteriaSection
