import React from "react"
import ForestSectionContent from "./ForestSectionContent"
import ForestBackground from "./ForestBackground"

const ForestSection = () => {
  return (
    <>
      <ForestBackground />
      <div className="forestOverlay">
        <div className="forestBg"></div>
        <ForestSectionContent />
      </div>
    </>
  )
}

export default ForestSection
