import React, { useState, useEffect } from "react"
import useHeight from "../../hooks/useHeight"
import ForestBackgroundImage from "./ForestBackgroundImage"

const ForestBackground = () => {
  const [play, setPlay] = useState(true)
  const [animation, setAnimation] = useState(false)
  const [vHeight, setvHeight] = useState(0)
  const scrollOffset = useHeight()

  useEffect(() => {
    setvHeight(scrollOffset.height)
  }, [scrollOffset])

  useEffect(() => {
    if (vHeight >= 140) {
      setAnimation(true)
    }
  }, [vHeight])

  useEffect(() => {
    const playAnimation = () => {
      setPlay(true)
    }

    if (document.readyState === "complete") {
      setPlay(true)
    } else {
      window.addEventListener("load", playAnimation)
    }

    return () => {
      window.removeEventListener("load", playAnimation)
    }
  }, [])

  /* const [blur, setBlur] = useState(0)
  const [opacity, setOpacity] = useState(1)

  console.log(window.pageYOffset) */

  /* useEffect(() => {
    const animateForest = () => {
      if (window.pageYOffset < 160) {
        setOpacity(1)
      }
      if (window.pageYOffset > 160 && window.pageYOffset < 300) {
        setOpacity(window.pageYOffset / (50 * 100))
      }
      if (window.pageYOffset > 300) {
        setOpacity(0.5)
      }
    }
    window.addEventListener("scroll", animateForest)
    return () => {
      window.removeEventListener("scroll", animateForest)
    }
  }, []) */

  return (
    <div className={`forestWrapper ${vHeight >= 140 && play ? "blur" : ""} ${vHeight < 140 && animation ? "blurOut" : ""}`}>
      <ForestBackgroundImage />
    </div>
  )
}

export default ForestBackground

/* ${vHeight >= 140 && play === true ? "blur" : ""} ${vHeight < 140 && animation ? "blurOut" : ""} */
