import { useState, useEffect } from "react";

export default function useWidth() {
  const isClient = typeof window === "object" ? true : false;
  const [size, setSize] = useState({
    width: isClient ? window.innerWidth : null,
  });

  useEffect(() => {
    const handleResize = () =>
      setSize({
        width: isClient ? window.innerWidth : null,
      });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isClient]);

  return size;
}
