import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import useFooterImages from "../../hooks/useFooterImages"

const FooterContent = ({ inViewport, forwardedRef, enterCount }) => {
  const logoSources = useFooterImages()

  const [cssLogo, setCssLogo] = useState("logo")
  const [cssFB, setcCssFB] = useState("")
  const [cssIG, setCssIG] = useState("")

  useEffect(() => {
    if (inViewport && enterCount === 1) {
      setCssLogo("logo cardPop")
      setcCssFB("popFB")
      setCssIG("popIG")
    }

    if (enterCount > 1) {
      setCssLogo("logo show")
      setcCssFB("show")
      setCssIG("show")
    }
  }, [enterCount, inViewport])

  return (
    <div className="content" ref={forwardedRef}>
      <Img className={cssLogo} fluid={logoSources} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="logo" />
      <p>Copyright © 2020 KOH-I-NOOR HARDTMUTH a.s., F. A. Gerstnera 21/3, 370 01 České Budějovice</p>
      <div className="social">
        <a href="#a">
          <img className={cssFB} src="/img/facebook-footer.svg" width="100%" height="auto" alt="facebookIcon" />
        </a>
        <a href="#a">
          <img className={cssIG} src="/img/instagram-footer.svg" width="100%" height="auto" alt="instagramIcon" />
        </a>
      </div>
    </div>
  )
}

export default FooterContent
