import { useState, useEffect } from "react";

export default function useHeight() {
  const isClient = typeof window === "object" ? true : false;
  const [size, setSize] = useState({
    //width: isClient ? window.innerHeight : null,
    height: isClient ? window.pageYOffset : null,
  });

  useEffect(() => {
    const handleScroll = () =>
      setSize({
        //width: isClient ? window.innerWidth : null,
        height: isClient ? window.pageYOffset : null,
      });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isClient]);

  return size;
}
