import React, { useState, useEffect } from "react"

const CardTextFirst = ({ inViewport, forwardedRef, enterCount, desktop, mobile }) => {
  const [css, setcss] = useState("cardText")

  useEffect(() => {
    // setcounter(value => value + 1)

    if (inViewport && enterCount === 1) {
      setcss("cardText cardPop")
    }

    if (enterCount > 1) {
      setcss("cardText show")
    }
  }, [enterCount, inViewport])

  return (
    <div className={`${css} ${desktop ? "desktop" : mobile ? "mobile" : ""}`} ref={forwardedRef}>
      <h2>
        Zajímavosti o Sibiřském cedru aneb
        <br />
        jakou moudrost skrývá Císař tajgy
      </h2>
      <p>
        Pastelkoví kamarádi Pen a Peny se rozhodli uskutečnit dalekou cestu na Sibiř, pátrat po svém původu. Na své cestě potkali nejstarší strom, praotce všech sibiřských cedrů
        přezdívaného místními obyvateli Císař tajgy nebo také Strom zázraků.
      </p>
      <p>Jaká tajemství se od něj dozvěděli a čím je strom, ze kterého jsou vyrobeni tak zajímavý?</p>
      <p>
        Sibiřský cedr je velmi vyhledáván například pro léčivé účinky semínek šišek, které Sibiřané nazývají oříšky. Také jeho dřevo je velmi ceněné pro své blahodárné účinky
        například při nespavosti, stresu, onemocnění dýchacích cest či dezinfekční vlastnosti.
      </p>
      <img src="img/path-arrow-right.svg" alt="path" />
    </div>
  )
}

export default CardTextFirst
