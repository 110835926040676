import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const PenPenyShining = ({ inViewport, forwardedRef, penPenyShiningImage }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(name: { eq: "lights-pen-peny-shining@3x" }, relativeDirectory: { eq: "lightsPenPenyShining" }) {
          childImageSharp {
            fluid(maxWidth: 1437, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const css = inViewport ? "penPenyShining animate" : "penPenyShining"

  return (
    <div className={css} ref={forwardedRef}>
      <BackgroundImage fluid={data.desktop.childImageSharp.fluid} className="bg">
        <Img fluid={penPenyShiningImage} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="penPenyShining" />
      </BackgroundImage>
    </div>
  )
}

export default PenPenyShining
