import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer/Footer"
import Intro from "../components/Intro/Intro"
import ForestSection from "../components/ForestSection/ForestSection"
import TextSection from "../components/TextSection/TextSection"
import BacteriaSection from "../components/BacteriaSection/BacteriaSection"
import FinalSection from "../components/FinalSection/FinalSection"

const IndexPage = props => {
  const [pageLoaded, setPageLoaded] = useState(false)
  const showElements = () => {
    setPageLoaded(true)
  }
  useEffect(() => {
    if (document.readyState === "complete") {
      showElements()
    } else {
      window.addEventListener("load", showElements, false)
    }
    // window.addEventListener("load", () => setPageLoaded(true), false)
    //window.onload = showElements()
    return () => {
      window.removeEventListener("load", showElements, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Koh-i-noor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Koh-i-noor</title>
        <html lang="en" amp />
      </Helmet>
      <div className="app" style={{ opacity: `${pageLoaded ? "1" : "0"}` }}>
        <Header />

        <Intro />

        <ForestSection />

        <TextSection />

        <BacteriaSection />

        <FinalSection />

        <Footer />
      </div>
    </>
  )
}

export default IndexPage
