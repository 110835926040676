import React from "react"
import handleViewport from "react-in-viewport"
import CardTextSecond from "../CardTexts/CardTextSecond"
import PenPenyLighting from "./PenPenyLighting"
const TextSection = () => {
  const ViewportBlockCardTextSecond = handleViewport(CardTextSecond)
  const ViewportBlockPenPenyLighting = handleViewport(PenPenyLighting)

  return (
    <>
      <div className="textSectionOverlay" id="textSection" />
      <section className="textSection">
        <div className="line">
          <ViewportBlockPenPenyLighting />
          <ViewportBlockCardTextSecond />
        </div>
      </section>
    </>
  )
}

export default TextSection
