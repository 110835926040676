import React, { useState, useEffect } from "react"

const CardTextFourth = ({ inViewport, forwardedRef, enterCount }) => {
  const [css, setCss] = useState("cardText fourth")

  useEffect(() => {
    if (inViewport && enterCount === 1) {
      setCss("cardText fourth cardPop")
    }

    if (enterCount > 1) {
      setCss("cardText fourth show")
    }
  }, [enterCount, inViewport])

  return (
    <div className={css} ref={forwardedRef}>
      <h2>TAdy je závěrčný text o patelkách Koh-i-nooru</h2>
      <p>
        At vero eos et dolore disputandum putant sed ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat hanc ego cum memoriter,
        tum etiam ac ratione et harum quidem rerum hic tenetur a philosophis compluribus permulta dicantur, cur nec me ab illo inventore.
      </p>
      <p>
        Alii autem, quibus ego cum memoriter, tum etiam ac ratione et dolorum effugiendorum gratia autem, quibus ego assentior, cum memoriter, tum etiam ac ratione intellegi posse
        et dolorem? sunt autem vel eum iure reprehenderit, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, quid.
      </p>
    </div>
  )
}

export default CardTextFourth
