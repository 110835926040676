import React, { useState, useEffect } from "react"

const CardTextSecond = ({ inViewport, forwardedRef, enterCount }) => {
  const [css, setCss] = useState("cardText second")
  useEffect(() => {
    if (inViewport && enterCount === 1) {
      setCss("cardText second cardPop")
    }

    if (enterCount > 1) {
      setCss("cardText second show")
    }
  }, [enterCount, inViewport])

  return (
    <div className={css} ref={forwardedRef}>
      <h2>Kombinace cedrového dřeva a speciální antibakteriální vrstvy</h2>
      <p>Sibiřský cedr má krásnou texturu a dokonce působí výborně i energeticky. Sibiřští domorodci ho proto nosí jako přívěšek či amulet.</p>
      <p>“Peny, věděla jsi o nás všechny tyhle zajímavé věci?” Pene, nevěděla a ještěže jsme se na tuhle výpravu vydali!”</p>
      <p>
        “Ještě bychom také měli prozradit, že kromě toho, z jakého vzácného dřeva jsme vyrobeni, máme na sobě také ještě speciální vrstvu, kterou vyvinuli v Koh-i-nooru proto, aby
        se na nás nezachytávaly různé nežádoucí bakterie a kreslení s námi byla jenom radost. Což samozřejmě, děti, neznamená, že si stále nemusíte mýt ruce!”
      </p>
      <img src="img/path-arrow-left.svg" alt="path" />
    </div>
  )
}

export default CardTextSecond
