import React from "react"
import Img from "gatsby-image"
import useImages from "../../hooks/useImages"
import handleViewport from "react-in-viewport"
import CardTextThird from "../CardTexts/CardTextThird"
import BacteriaTopRight from "./Animations/BacteriaTopRight"
import BacteriaMiddleLeft from "./Animations/BacteriaMiddleLeft"
import BacteriaMiddleRight from "./Animations/BacteriaMiddleRight"
import BacteriaMiddleDown from "./Animations/BacteriaMiddleDown"
import PenPenyShining from "./PenPenyShining"

const BacteriaSectionDesktop = React.memo(() => {
  const images = useImages()

  const ViewportBlockCardTextThird = handleViewport(CardTextThird /** options: {}, config: {} **/)
  const ViewportBlockBacteriaTopRight = handleViewport(BacteriaTopRight /** options: {}, config: {} **/)
  const ViewportBlockBacteriaMiddleLeft = handleViewport(BacteriaMiddleLeft /** options: {}, config: {} **/)
  const ViewportBlockBacteriaMiddleRight = handleViewport(BacteriaMiddleRight /** options: {}, config: {} **/)
  const ViewportBlockBacteriaMiddleDown = handleViewport(BacteriaMiddleDown /** options: {}, config: {} **/)
  const ViewportBlockPenPenyShining = handleViewport(PenPenyShining /** options: {}, config: {} **/)

  return (
    <>
      <div className="bacteriaTop">
        <ViewportBlockCardTextThird />

        <ViewportBlockBacteriaTopRight virusImg={images.virusSources} trususImg={images.trususSources} salamolosusImg={images.salamolosusSources} />
      </div>

      <div className="bacteriaMiddle">
        <div className="line">
          <ViewportBlockBacteriaMiddleLeft
            bacteriaLeftImg={images.bacteriaLeftSources}
            bacteriaLeft1Img={images.bacteriaLeft1Sources}
            bacteriaLeft2Img={images.bacteriaLeft2Sources}
            bacteriaLeft3Img={images.bacteriaLeft3Sources}
            bacteriaLeft4Img={images.bacteriaLeft4Sources}
          />

          <ViewportBlockPenPenyShining penPenyShiningImage={images.penPenyShiningSources} />

          <ViewportBlockBacteriaMiddleRight
            bacteriaRightImg={images.bacteriaRightSources}
            bacteriaRight1Img={images.bacteriaRight1Sources}
            bacteriaRight2Img={images.bacteriaRight2Sources}
          />
        </div>
        <Img className="shadow" fluid={images.shadowSources} style={{ width: "100%" }} imgStyle={{ width: "100%", objectFit: "contain" }} alt="shadow" />

        <ViewportBlockBacteriaMiddleDown
          bacteriaBottomImg={images.bacteriaBottomSources}
          bacteriaBottom1Img={images.bacteriaBottom1Sources}
          bacteriaBottom2Img={images.bacteriaBottom2Sources}
        />
      </div>
      <div className="bacteriaBottom">
        <div className="moribundus">
          <Img fluid={images.moribundusSources} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="moribundus" />
        </div>
        <div className="brutus">
          <Img fluid={images.brutusSources} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="brutus" />
        </div>
      </div>
    </>
  )
})

export default BacteriaSectionDesktop
