import React from "react"

const IntroButton = ({ inViewport, forwardedRef, enterCount }) => {
  let css = inViewport ? "button buttonPop" : "button"

  if (enterCount > 1) {
    css = "button show"
  }

  return (
    <div className={css} ref={forwardedRef}>
      Chci vědět víc
    </div>
  )
}

export default IntroButton
