import React from "react"
import Img from "gatsby-image"
import useImages from "../../hooks/useImages"
import handleViewport from "react-in-viewport"
import CardTextThird from "../CardTexts/CardTextThird"
import ResponsiveBacteriaTopRight from "./Animations/ResponsiveBacteriaTopRight"
import ResponsiveBacteriaMiddleLeft from "./Animations/ResponsiveBacteriaMiddleLeft"

const BacteriaSectionResponsive = React.memo(() => {
  const images = useImages()

  const ViewportBlockCardTextThird = handleViewport(CardTextThird /** options: {}, config: {} **/)
  const ViewportBlockBacteriaTopRight = handleViewport(ResponsiveBacteriaTopRight /** options: {}, config: {} **/)
  const ViewportBlockBacteriaMiddleLeft = handleViewport(ResponsiveBacteriaMiddleLeft /** options: {}, config: {} **/)

  return (
    <>
      <div className="bacteriaMiddle">
        <ViewportBlockBacteriaTopRight virusImg={images.virusFlipSources} trususImg={images.trususSources} salamolosusImg={images.salamolosusSources} />

        <div className="line">
          <ViewportBlockBacteriaMiddleLeft bacteriaLeftImg={images.bacteriaLeftSources} bacteriaLeft1Img={images.bacteriaLeft1Sources} />

          <div className="penPenyShining">
            <Img
              fluid={images.penPenyShiningMobileSources}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="penPenyShining"
            />
          </div>

          <div className="bacteriaRight">
            <Img
              className="bacteriaRightImg"
              fluid={images.bacteriaRightSources}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="bacteriaRight"
            />
          </div>
        </div>

        <Img className="shadow" fluid={images.shadowSources} style={{ width: "100%" }} imgStyle={{ width: "100%", objectFit: "contain" }} alt="shadow" />
      </div>

      <div className="bacteriaBottom">
        <div className="moribundus">
          <Img fluid={images.moribundusSources} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="moribundus" />
        </div>
        <div className="brutus">
          <Img fluid={images.brutusSources} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="brutus" />
        </div>
      </div>

      <div className="bacteriaTop">
        <ViewportBlockCardTextThird />
      </div>
    </>
  )
})

export default BacteriaSectionResponsive
