import React from "react"
import Img from "gatsby-image"

const BacteriaMiddleDown = ({ inViewport, forwardedRef, bacteriaBottomImg, bacteriaBottom1Img, bacteriaBottom2Img }) => {
  const cssBacteriaDownImg1 = inViewport ? "bacteriaDownImg1 float" : "bacteriaDownImg1"
  const cssBacteriaDownImg2 = inViewport ? "bacteriaDownImg2 float2" : "bacteriaDownImg2"

  return (
    <div className="bacteriaDown" ref={forwardedRef}>
      <Img
        className="bacteriaDownImg"
        fluid={bacteriaBottomImg}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaDown"
      />
      <Img
        className={cssBacteriaDownImg1}
        fluid={bacteriaBottom1Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaDown"
      />
      <Img
        className={cssBacteriaDownImg2}
        fluid={bacteriaBottom2Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaDown"
      />
    </div>
  )
}

export default BacteriaMiddleDown
