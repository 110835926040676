import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import useWidth from "../../hooks/useWidth"

const PenPenyBox = ({ inViewport, forwardedRef, penPenyimage, penPenyMobileImage }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(name: { eq: "lights-pen-peny-box@2x" }, relativeDirectory: { eq: "lightsPenPenyBox" }) {
          childImageSharp {
            fluid(maxWidth: 1996, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  const viewportWidth = useWidth()
  const [wWidth, setwWidth] = useState(viewportWidth.width)

  useEffect(() => {
    setwWidth(viewportWidth.width)
  }, [viewportWidth])

  const css = inViewport ? "penPenyBox animate" : "penPenyBox"

  return (
    <div className={css} ref={forwardedRef}>
      <BackgroundImage fluid={data.desktop.childImageSharp.fluid} className="bg">
        <Img fluid={wWidth > 1440 ? penPenyimage : penPenyMobileImage} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="penPenyBox" />
      </BackgroundImage>
    </div>
  )
}

export default PenPenyBox
