import React from "react"
import handleViewport from "react-in-viewport"
import CardTextFourth from "../CardTexts/CardTextFourth"
import FinalButton from "./FinalButton"

const FinalBlock = () => {
  const ViewportBlockCardTextFourth = handleViewport(CardTextFourth /** options: {}, config: {} **/)
  const ViewportBlockButtonSecond = handleViewport(FinalButton /** options: {}, config: {} **/)

  return (
    <div className="overlay">
      <section className="container">
        <ViewportBlockButtonSecond />

        {/* <ViewportBlockCardTextFourth /> */}
      </section>
    </div>
  )
}

export default FinalBlock
