import React from "react"
import handleViewport from "react-in-viewport"
import cardTextFirst from "../CardTexts/CardTextFirst"
import useImages from "../../hooks/useImages"
import Img from "gatsby-image"

const ForestSectionContent = () => {
  const images = useImages()
  const ViewportBlockCardTextFirst = handleViewport(cardTextFirst /* {}, { disconnectOnLeave: true } */)

  return (
    <section className="forestSection" id="forestSection">
      <div className="line">
        <div className="block">
          <div className="badgeText" />
          <div className="penPeny">
            <Img fluid={images.penPenySources} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="penPeny" />
          </div>
          <ViewportBlockCardTextFirst desktop={true} mobile={false} />
        </div>

        <div className="tree">
          <Img fluid={images.treeSources} style={{ width: "100%", height: "100%" }} imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }} alt="tree" />
        </div>
      </div>

      <ViewportBlockCardTextFirst dekstop={false} mobile={true} />
    </section>
  )
}

export default ForestSectionContent
