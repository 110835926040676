import React from "react"
import Img from "gatsby-image"

const BacteriaMiddleLeft = ({ inViewport, forwardedRef, bacteriaLeftImg, bacteriaLeft1Img, bacteriaLeft2Img, bacteriaLeft3Img, bacteriaLeft4Img }) => {
  const cssBacteriaLeftImg = inViewport ? "bacteriaLeftImg float" : "bacteriaLeftImg"
  const cssBacteriaLeftImg2 = inViewport ? "bacteriaLeftImg2 float2" : "bacteriaLeftImg2"
  const cssBacteriaLeftImg3 = inViewport ? "bacteriaLeftImg3 float3" : "bacteriaLeftImg3"

  return (
    <div className="bacteriaLeft" ref={forwardedRef}>
      <Img
        className={cssBacteriaLeftImg}
        fluid={bacteriaLeftImg}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaLeft"
      />
      <Img
        className="bacteriaLeftImg1"
        fluid={bacteriaLeft1Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaLeft"
      />
      <Img
        className={cssBacteriaLeftImg2}
        fluid={bacteriaLeft2Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaLeft"
      />
      <Img
        className={cssBacteriaLeftImg3}
        fluid={bacteriaLeft3Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaLeft"
      />
      <Img
        className="bacteriaLeftImg4"
        fluid={bacteriaLeft4Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaLeft"
      />
    </div>
  )
}

export default BacteriaMiddleLeft
