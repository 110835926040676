import React from "react"
import Img from "gatsby-image"
import useImages from "../../hooks/useImages"
import FinalBlock from "./FinalBlock"
import PenPenyBox from "./PenPenyBox"
import handleViewport from "react-in-viewport"

const FinalSection = () => {
  const images = useImages()

  const ViewportBlockPenPenyBox = handleViewport(PenPenyBox /** options: {}, config: {} **/)

  return (
    <div className="finalSectionOverlay">
      {/* <section className="finalSection">
        <div className="container">
          <div className="headline secondHeadline">
            Tohle bude finální
            <br />
            nadpis toho co chceme říct o naších pastelkách
          </div>
        </div>
      </section> */}
      <div className="container finalPenPeny">
        <div className="images">
          <Img
            className="bacteriaSmallleft"
            fluid={images.bacteriaSmallleftSources}
            style={{ width: "100%", height: "100%" }}
            imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
            alt="bacteriaSmallleft"
          />

          <ViewportBlockPenPenyBox penPenyimage={images.penPenyBoxSources} penPenyMobileImage={images.penPenyBoxMobileSources} />

          <Img
            className="bacteriaSmallright"
            fluid={images.bacteriaSmallrightSources}
            style={{ width: "100%", height: "100%" }}
            imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
            alt="bacteriaSmallright"
          />
        </div>
      </div>
      <FinalBlock />
    </div>
  )
}

export default FinalSection
