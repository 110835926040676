import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import useImages from "../../hooks/useImages"
import useWidth from "../../hooks/useWidth"

const PenPenyLighting = ({ inViewport, forwardedRef }) => {
  const images = useImages()

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(name: { eq: "lights-for-pen-peny-lights@3x" }, relativeDirectory: { eq: "lightsPenPenyLights" }) {
          childImageSharp {
            fluid(maxWidth: 2268, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const css = inViewport ? "penPenyLighting animate" : "penPenyLighting"

  const viewportWidth = useWidth()
  const [wWidth, setwWidth] = useState(viewportWidth.width)

  useEffect(() => {
    setwWidth(viewportWidth.width)
  }, [viewportWidth])

  return (
    <div className={css} ref={forwardedRef}>
      <BackgroundImage fluid={data.desktop.childImageSharp.fluid} className="bg">
        <Img
          className="penPeny"
          fluid={wWidth > 1440 ? images.penPenyLightsSources : images.penPenyLightsMobileSources}
          style={{ width: "100%", height: "100%", maxHeight: "455px" }}
          imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="penPenyLighting"
        />
      </BackgroundImage>
    </div>
  )
}

export default PenPenyLighting
