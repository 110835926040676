import React from "react"
import Img from "gatsby-image"

const ResponsiveBacteriaMiddleLeft = ({ inViewport, forwardedRef, bacteriaLeftImg, bacteriaLeft1Img }) => {
  const cssBacteriaLeftImg = inViewport ? "bacteriaLeftImg float" : "bacteriaLeftImg"

  return (
    <div className="bacteriaLeft" ref={forwardedRef}>
      <Img
        className={cssBacteriaLeftImg}
        fluid={bacteriaLeftImg}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaLeft"
      />
      <Img
        className="bacteriaLeftImg1"
        fluid={bacteriaLeft1Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaLeft1"
      />
    </div>
  )
}

export default ResponsiveBacteriaMiddleLeft
