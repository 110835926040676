import React from "react"
import handleViewport from "react-in-viewport"
import FooterContent from "./FooterContent"

const Footer = () => {
  const ViewportBlockFooterContent = handleViewport(FooterContent)

  return (
    <footer>
      <ViewportBlockFooterContent />
    </footer>
  )
}
export default Footer
