import React from "react"
import handleViewport from "react-in-viewport"
import IntroButton from "./IntroButton"

const Intro = () => {
  const ViewportBlockButtonFirst = handleViewport(IntroButton /** options: {}, config: {} **/)
  return (
    <div className="container intro">
      <div className="headline">
        Vydej se s Pen a Peny, pastelkami STOP Bacteria, po stopách vzácného cedru <br />
        až na Sibiř!{" "}
      </div>
      <ViewportBlockButtonFirst />
    </div>
  )
}

export default Intro
