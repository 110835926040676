import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ForestBackgroundImage = React.memo(() => {
  const data = useStaticQuery(
    graphql`
      query {
        mobile: file(name: { eq: "forest" }, relativeDirectory: { eq: "forest" }) {
          childImageSharp {
            fluid(maxWidth: 1621, pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        tablet: file(name: { eq: "forest@2x" }, relativeDirectory: { eq: "forest" }) {
          childImageSharp {
            fluid(maxWidth: 3242, srcSetBreakpoints: [728, 1200, 1440], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        desktop: file(name: { eq: "forest@3x" }, relativeDirectory: { eq: "forest" }) {
          childImageSharp {
            fluid(maxWidth: 4863, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const sources = [
    data.desktop.childImageSharp.fluid,
    {
      ...data.tablet.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...data.mobile.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ]

  return <BackgroundImage fluid={sources} className="forest" /* style={{  filter: `blur(${blur})`, opacity: opacity }} */ />
})

export default ForestBackgroundImage
