import React, { useState, useEffect } from "react"

const CardTextThird = React.memo(({ inViewport, forwardedRef, enterCount }) => {
  const [css, setCss] = useState("cardText third")
  useEffect(() => {
    if (inViewport && enterCount === 1) {
      setCss("cardText third cardPop")
    }

    if (enterCount > 1) {
      setCss("cardText third show")
    }
  }, [enterCount, inViewport])

  return (
    <div className={css} ref={forwardedRef}>
      <h2>Zdravé kreslení s Pen a Peny od Koh-i-nooru</h2>
      <p>
        Pastelky STOP Bacteria díky svému antibakteriálnímu povrchu zamezují množení některých bakterií a proto i například při kreslení v kolektivu nepodporují přenos dotykem.
        Tato ochranná vrstva je nesmývatelná, trvalá, a proto vydrží stejně tak dlouho, jako pastelky samotné.
      </p>
      <p>Svým trojhranným ergonomickým tvarem umožňují pastelky co nejjednodušší a správné držení.</p>
    </div>
  )
})

export default CardTextThird
