import { graphql, useStaticQuery } from "gatsby"

const useImages = () => {
  const data = useStaticQuery(graphql`
    {
      forest: allFile(filter: { relativeDirectory: { eq: "forest" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1621, srcSetBreakpoints: [320, 414, 728, 1621, 1920], sizes: "[320, 414, 728, 1621]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      tree: allFile(filter: { relativeDirectory: { eq: "tree" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 869, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      penPeny: allFile(filter: { relativeDirectory: { eq: "penPeny" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 651, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      penPenyLights: allFile(filter: { relativeDirectory: { eq: "penPenyLights" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 528, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      penPenyLightsMobile: allFile(filter: { relativeDirectory: { eq: "penPenyLightsMobile" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 756, srcSetBreakpoints: [728, 1200, 1440], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      lightsPenPenyLights: allFile(filter: { relativeDirectory: { eq: "lightsPenPenyLights" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 756, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      virus: allFile(filter: { relativeDirectory: { eq: "virus" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 544, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      virusFlip: allFile(filter: { relativeDirectory: { eq: "virusFlip" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 544, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      trusus: allFile(filter: { relativeDirectory: { eq: "trusus" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 431, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      salamolosus: allFile(filter: { relativeDirectory: { eq: "salamolosus" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 431, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaLeft: allFile(filter: { relativeDirectory: { eq: "bacteriaLeft" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 132, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaLeft1: allFile(filter: { relativeDirectory: { eq: "bacteriaLeft1" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 117, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaLeft2: allFile(filter: { relativeDirectory: { eq: "bacteriaLeft2" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 78, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaLeft3: allFile(filter: { relativeDirectory: { eq: "bacteriaLeft3" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 138, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaLeft4: allFile(filter: { relativeDirectory: { eq: "bacteriaLeft4" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 131, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      penPenyShining: allFile(filter: { relativeDirectory: { eq: "penPenyShining" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 419, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      penPenyShiningMobile: allFile(filter: { relativeDirectory: { eq: "penPenyShiningMobile" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 419, srcSetBreakpoints: [728, 1200, 1440], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      shadow: allFile(filter: { relativeDirectory: { eq: "shadow" } }) {
        nodes {
          childImageSharp {
            fluid(maxHeight: 49, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaRight: allFile(filter: { relativeDirectory: { eq: "bacteriaRight" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 271, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaRight1: allFile(filter: { relativeDirectory: { eq: "bacteriaRight1" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 260, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaRight2: allFile(filter: { relativeDirectory: { eq: "bacteriaRight2" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 116, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaBottom: allFile(filter: { relativeDirectory: { eq: "bacteriaBottom" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 170, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaBottom1: allFile(filter: { relativeDirectory: { eq: "bacteriaBottom1" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 88, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaBottom2: allFile(filter: { relativeDirectory: { eq: "bacteriaBottom2" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 107, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      moribundus: allFile(filter: { relativeDirectory: { eq: "moribundus" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 578, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      brutus: allFile(filter: { relativeDirectory: { eq: "brutus" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 536, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      penPenyBox: allFile(filter: { relativeDirectory: { eq: "penPenyBox" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 959, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      lightsPenPenyBox: allFile(filter: { relativeDirectory: { eq: "lightsPenPenyBox" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 998, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      penPenyBoxMobile: allFile(filter: { relativeDirectory: { eq: "penPenyBoxMobile" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1005, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaSmallleft: allFile(filter: { relativeDirectory: { eq: "bacteriaSmallleft" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 122, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      bacteriaSmallright: allFile(filter: { relativeDirectory: { eq: "bacteriaSmallright" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 73, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      logo: allFile(filter: { relativeDirectory: { eq: "logo" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 117, srcSetBreakpoints: [728, 1200, 1440, 1920, 2280, 4320], sizes: "[728, 1200, 1440]", pngQuality: 90) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `)

  const images = {
    forestSources: [
      data.forest.nodes[2].childImageSharp.fluid,
      {
        ...data.forest.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.forest.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    penPenySources: [
      data.penPeny.nodes[2].childImageSharp.fluid,
      {
        ...data.penPeny.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.penPeny.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    treeSources: [
      data.tree.nodes[2].childImageSharp.fluid,
      {
        ...data.tree.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.tree.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    penPenyLightsSources: [
      data.penPenyLights.nodes[2].childImageSharp.fluid,
      {
        ...data.penPenyLights.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.penPenyLights.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    lightsPenPenyLightsSources: [
      data.lightsPenPenyLights.nodes[2].childImageSharp.fluid,
      {
        ...data.lightsPenPenyLights.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.lightsPenPenyLights.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    penPenyLightsMobileSources: [
      data.penPenyLightsMobile.nodes[2].childImageSharp.fluid,
      {
        ...data.penPenyLightsMobile.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.penPenyLightsMobile.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    virusSources: [
      data.virus.nodes[2].childImageSharp.fluid,
      {
        ...data.virus.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.virus.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    virusFlipSources: [
      data.virusFlip.nodes[2].childImageSharp.fluid,
      {
        ...data.virusFlip.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.virusFlip.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    trususSources: [
      data.trusus.nodes[2].childImageSharp.fluid,
      {
        ...data.trusus.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.trusus.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    salamolosusSources: [
      data.salamolosus.nodes[2].childImageSharp.fluid,
      {
        ...data.salamolosus.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.salamolosus.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaLeftSources: [
      data.bacteriaLeft.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaLeft.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaLeft.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaLeft1Sources: [
      data.bacteriaLeft1.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaLeft1.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaLeft1.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaLeft2Sources: [
      data.bacteriaLeft2.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaLeft2.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaLeft2.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaLeft3Sources: [
      data.bacteriaLeft3.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaLeft3.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaLeft3.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaLeft4Sources: [
      data.bacteriaLeft4.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaLeft4.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaLeft4.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    penPenyShiningSources: [
      data.penPenyShining.nodes[2].childImageSharp.fluid,
      {
        ...data.penPenyShining.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.penPenyShining.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    penPenyShiningMobileSources: [
      data.penPenyShiningMobile.nodes[2].childImageSharp.fluid,
      {
        ...data.penPenyShiningMobile.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.penPenyShiningMobile.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    shadowSources: [
      data.shadow.nodes[2].childImageSharp.fluid,
      {
        ...data.shadow.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.shadow.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaRightSources: [
      data.bacteriaRight.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaRight.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaRight.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaRight1Sources: [
      data.bacteriaRight1.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaRight1.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaRight1.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaRight2Sources: [
      data.bacteriaRight2.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaRight2.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaRight2.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaBottomSources: [
      data.bacteriaBottom.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaBottom.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaBottom.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaBottom1Sources: [
      data.bacteriaBottom1.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaBottom1.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaBottom1.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaBottom2Sources: [
      data.bacteriaBottom2.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaBottom2.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaBottom2.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    moribundusSources: [
      data.moribundus.nodes[2].childImageSharp.fluid,
      {
        ...data.moribundus.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.moribundus.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    brutusSources: [
      data.brutus.nodes[2].childImageSharp.fluid,
      {
        ...data.brutus.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.brutus.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaSmallleftSources: [
      data.bacteriaSmallleft.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaSmallleft.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaSmallleft.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    penPenyBoxSources: [
      data.penPenyBox.nodes[2].childImageSharp.fluid,
      {
        ...data.penPenyBox.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.penPenyBox.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    ligtsPenPenyBoxSources: [
      data.lightsPenPenyBox.nodes[2].childImageSharp.fluid,
      {
        ...data.lightsPenPenyBox.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.lightsPenPenyBox.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    penPenyBoxMobileSources: [
      data.penPenyBoxMobile.nodes[2].childImageSharp.fluid,
      {
        ...data.penPenyBoxMobile.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.penPenyBoxMobile.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
    bacteriaSmallrightSources: [
      data.bacteriaSmallright.nodes[2].childImageSharp.fluid,
      {
        ...data.bacteriaSmallright.nodes[1].childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
      {
        ...data.bacteriaSmallright.nodes[0].childImageSharp.fluid,
        media: `(max-width: 640px)`,
      },
    ],
  }
  return images
}
export default useImages
