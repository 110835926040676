import React from "react"
import Img from "gatsby-image"

const BacteriaMiddleRight = ({ inViewport, forwardedRef, bacteriaRightImg, bacteriaRight1Img, bacteriaRight2Img }) => {
  const cssBacteriaRightImg1 = inViewport ? "bacteriaRightImg1 float3" : "bacteriaRightImg1"
  const cssBacteriaRightImg2 = inViewport ? "bacteriaRightImg2 float2" : "bacteriaRightImg2"

  return (
    <div className="bacteriaRight" ref={forwardedRef}>
      <Img
        className="bacteriaRightImg"
        fluid={bacteriaRightImg}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaRight"
      />
      <Img
        className={cssBacteriaRightImg1}
        fluid={bacteriaRight1Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaRight"
      />
      <Img
        className={cssBacteriaRightImg2}
        fluid={bacteriaRight2Img}
        style={{ width: "100%", height: "100%" }}
        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
        alt="bacteriaRight"
      />
    </div>
  )
}

export default BacteriaMiddleRight
